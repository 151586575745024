html {
  /* background-color: #181818; */
}

.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  /* padding: 20px; */
}

#content {
  width: 100%;
}

.react-grid-layout {
  /* background: #eee; */
  background: #fff;
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
  max-width: 100%;
}

.react-grid-item:not(.react-grid-placeholder) {
  /*background: #ccc;
  border: 1px solid black;*/
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  /* background: #cce; */
  /*background: #ccc;
  /* background: #fff; */
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.toolbox__items {
  display: block;
}

.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}

.droppable-element {
  width: 150px;
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.react-grid-layout {
  width: 100%;
  /* height: 100% !important; */
  position: relative;
  transition: height 200ms ease;
  background-color: #f2f2f2;
  color: #fff;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-handle {
  display: none;
}

div.App-backgroundTheme-37 {
  background-color: #fff !important;
  min-height: calc(100vh - 10.8rem) !important;
}

.react-grid-item.empty-space.react-grid-placeholder {
  background-color: transparent !important;
}

.trademark:after {
  content: " \2122";
}